<template>
  <v-container :style="`width: ${($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '' : '1200px !important'};`">
    <v-layout
      wrap
      layout>
      <v-flex
        xs12
        md12>
        <p style="color: #ff6d6d;">{{ $t('thirdParty.infoReceiveVouchers') }}</p>
        <div>
          <v-icon
            class="mr-2"
            color="#01baef">mdi-account</v-icon>
          <span>
            {{ email ? fullname : (SHIPMENT_DETAILS_DATA && SHIPMENT_DETAILS_DATA.fullname ? SHIPMENT_DETAILS_DATA.fullname : '') }}
          </span>
          <span class="mx-3">|</span>
          <span>{{ email ? phone : (SHIPMENT_DETAILS_DATA && SHIPMENT_DETAILS_DATA.phone ? SHIPMENT_DETAILS_DATA.phone : '') }}</span>
        </div>
        <div class="d-flex justify-content-around">
          <div>
            <span><v-icon
              class="mr-2"
              color="#01baef">mdi-email</v-icon>{{ email ? email : (SHIPMENT_DETAILS_DATA && SHIPMENT_DETAILS_DATA.email ? SHIPMENT_DETAILS_DATA.email : '') }}</span>
          </div>
          <div
            class="text-right"
            @click="goToDeliveryInfo()">
            <v-icon
              medium
              @click="goToDeliveryInfo()">mdi-chevron-right</v-icon>
          </div>
        </div>
        <div>
          <span style="color: red">{{ $t('thirdParty.warningMessage') }}</span>
        </div>
      </v-flex>
      <v-flex
        xs12
        md12
        class="mt-2 py-2"
        style="border-bottom: 1px solid #e5e5e5; border-top: 1px solid #e5e5e5;">
        <div class="d-flex justify-content-around align-center">
          <div>
            <span>{{ $t('product.category.all') }} ({{ cartList.length }} {{ $t('thirdParty.product') }})</span>
          </div>
        </div>
      </v-flex>
      <v-flex
        xs12
        md7>
        <v-layout
          wrap
          layout>
          <v-flex xs12>
            <v-layout
              v-for="(cart, index) in cartList"
              :key="cart + index"
              class="pt-1 pb-1"
              wrap
              layout>
              <v-flex
                xs4
                md4
                style="align-self: center;">
                <figure
                  class="sixteen-nine-img">
                  <img
                    :src="cart.image">
                </figure>
              </v-flex>
              <v-flex
                class="pl-2"
                xs7
                md7>
                <div v-if="cart.warehouse > 0"><strong>{{ $t('product.detail.remainItems', {count: cart.warehouse}) }}</strong></div>
                <div
                  v-else
                  style="color: red"><strong>{{ $t('productOrder.outOfStock') }}</strong></div>
                <p>{{ cart.name }}</p>
                <div class="font-weight-bold mt-1">
                  <div
                    v-if="cart.pricePromo"
                    class="mr-2"
                    style="color: #ff6d6d;">{{ formatMoney(cart.pricePromo) }} VND</div>
                  <div
                    :style="cart.pricePromo ? 'text-decoration: line-through; font-size: 12px; font-weight: normal; color: #4B4B4B;' : 'color: #ff6d6d;'">{{ formatMoney(cart.price) }} VND</div>
                </div>
                <div
                  style="display: flex;">
                  <div
                    :class="cart.quantity > 1 ? 'cursor-pointer font-medium' : 'disable-btn font-medium'"
                    style="padding: 5px 15px 5px 15px; border: 1px solid #e5e5e5;"
                    @click="cart.quantity > 1 ? decreaseQuantity(cart) : ''"
                  >
                    -
                  </div>
                  <div
                    class="font-medium"
                    style="padding: 5px 15px 5px 15px; border: 1px solid #e5e5e5;"
                  >
                    {{ cart.quantity }}
                  </div>
                  <div
                    :class="cart.quantity < cart.warehouse ? 'cursor-pointer font-medium' : 'disable-btn font-medium'"
                    style="padding: 5px 15px 5px 15px; border: 1px solid #e5e5e5;"
                    @click="cart.quantity < cart.warehouse ? increaseQuantity(cart) : ''"
                  >
                    +
                  </div>
                </div>
                <div v-if="cart.numberOfUsesVoucherValid !== null">
                  <strong
                    :style="`color: ${cart.numberOfUsesVoucherValid === 0 ? 'red' : ''}`"> {{ $t('cart.productCanBuyTimes', {'0': cart.numberOfUsesVoucherValid}) }}
                  </strong>
                </div>
              </v-flex>
              <v-flex
                xs1
                md1
                style="align-self: center;">
                <div @click="onRemoveProductFromCart(cart.voucherOrderCartItemId)">
                  <v-icon color="#ff6d6d">mdi-delete</v-icon>
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex
        class="mt-4"
        style="border-top: 1px solid #e5e5e5;"
        xs12
        md12>
        <div class="d-flex mt-4">
          <div>
            <span class="d-block font-weight-bold">{{ $t('thirdParty.total') }}</span>
            <span
              class="font-weight-bold"
              style="color: #ff6d6d;">{{ getTotalPrice() }}</span>
          </div>
          <v-btn
            :loading="isLoading"
            :disabled="cartList.length <= 0"
            color="#ff6d6d"
            style="color: #fff;"
            @click="onGoChoosePaymentMethod()">
            {{ $t('product.detail.buy') + ' (' + cartList.length + ')' }}
          </v-btn>
        </div>
      </v-flex>
    </v-layout>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar" />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import functionUtils from 'utils/functionUtils'
import VoucherProductPromotionType from 'enum/voucherProductPromotionType'
import ToastType from 'enum/toastType'
import StoreChannelType from 'enum/storeChannelType'
import { isCookieEnabled, getCookie } from 'tiny-cookie'
export default {
  data: () => ({
    cartList: [],
    promotionCodeRules: [],
    promotionCode: null,
    valid: false,
    promotionCodeValid: false,
    priceReduced: 0,
    finalPrice: 0,
    promotionType: null,
    promotionId: null,
    promotionCodeOrigin: null,
    isLoading: false,
    tax: 0,
    paymentMethods: [],
    paymentMethodSelected: null,
    e1: 2,
    orderCode: null,
    totalPrice: 0,
    fullname: null,
    phone: null,
    email: null,
    agentDefaultId: null
  }),
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR',
      'GET_VOUCHER_ORDER_CART_LIST_DATA',
      'SHIPMENT_DETAILS_DATA',
      'IS_CHANGE_LOGIN_OR_LOGOUT_PARTNERSHIP'
    ])
  },
  watch: {
    GET_VOUCHER_ORDER_CART_LIST_DATA () {
      let data = this.GET_VOUCHER_ORDER_CART_LIST_DATA
      this.cartList = []
      data.forEach(e => {
        let pricePromo = e.extra_info.product ? e.extra_info.product.price_promo : null
        let price = pricePromo !== null ? pricePromo : e.voucher_info.price
        let item = {
          id: e.voucher_info.workpiece_id,
          image: e.voucher_info.image,
          quantity: e.quantity,
          price: price,
          name: e.voucher_info.name,
          serial: e.voucher_info.serial,
          voucherOrderCartItemId: e.id,
          warehouse: e.warehouse,
          numberOfUsesVoucherValid: e.number_of_uses_voucher_valid
        }
        this.cartList.push(item)
      })
    },
    IS_CHANGE_LOGIN_OR_LOGOUT_PARTNERSHIP: function () {
      this.getVoucherOrderCart()
    }
  },
  async created () {
    let filter = {
      params: {
        storeChannelId: this.getStoreChannelId()
      }
    }
    this.GET_DELIVERY_INFO_DETAIL(filter).then(
      function (res) {
        let data = res.data
        this.phone = data.phone
        this.email = data.email
        this.fullname = data.fullname
      }.bind(this)
    )
    await this.getVoucherOrderCart()
  },
  methods: {
    storeChannelsIdInRouteParams: function () {
      return functionUtils.getStoreChannelsIdInRouteParams(this.$route)
    },
    /**
     * Remove product from cart
     */
    onRemoveProductFromCart: function (id) {
      this.DELETE_VOUCHER_ORDER_CART({ id: id }).then(
        function () {
          this.getVoucherOrderCart()
          this.ON_SHOW_TOAST({
            'message': this.$t('common.success'),
            'styleType': ToastType.SUCCESS
          })
        }.bind(this)
      )
    },
    /**
     * Get price reduced
     */
    getPriceReduced: function () {
      // eslint-disable-next-line eqeqeq
      if (this.promotionType == VoucherProductPromotionType.PRICE) {
        return this.formatMoney(this.priceReduced)
      // eslint-disable-next-line eqeqeq
      } else if (this.promotionType == VoucherProductPromotionType.PERCENT) {
        return this.priceReduced + '%'
      }
    },
    /**
    * Go choose payment method
    */
    onGoChoosePaymentMethod: function () {
      let validateQuantityProduct = this.validateQuantityProduct()
      if (validateQuantityProduct) {
        this.$router.push({
          name: 'MobilePortalConfirmOrder',
          params: {
            'storeChannelId': this.getStoreChannelId()
          }
        })
      }
    },
    // validate quantity
    validateQuantityProduct: function () {
      let index = this.cartList.findIndex(x => x.numberOfUsesVoucherValid !== null && x.quantity > x.numberOfUsesVoucherValid)
      if (index !== -1) {
        this.ON_SHOW_TOAST({
          'message': this.$t('cart.validProductOnCart', { '0': this.cartList[index].name, '1': this.cartList[index].numberOfUsesVoucherValid }),
          'styleType': ToastType.ERROR
        })
        return false
      }
      return true
    },
    /**
     * Increase quantity
     */
    increaseQuantity: function (cart) {
      if (cart.numberOfUsesVoucherValid < cart.quantity + 1) {
        this.ON_SHOW_TOAST({
          'message': this.$t('cart.productCanBuyTimes', { '0': cart.numberOfUsesVoucherValid }),
          'styleType': ToastType.ERROR
        })
      } else {
        cart.quantity = cart.quantity + 1
        this.updateVoucherOrderCart(cart.voucherOrderCartItemId, cart.quantity)
        // this.getFinalPrice()
      }
    },
    decreaseQuantity: function (cart) {
      if (cart.quantity > 1) {
        cart.quantity = cart.quantity - 1
        this.updateVoucherOrderCart(cart.voucherOrderCartItemId, cart.quantity)
      }
      // this.getFinalPrice()
    },
    formatMoney: function (price) {
      return functionUtils.convertFormatNumberToMoney(price.toString())
    },
    /**
     * Get total price
     */
    getTotalPrice: function () {
      let price = 0
      for (let i = 0, size = this.cartList.length; i < size; i++) {
        price += (this.cartList[i].pricePromo ? this.cartList[i].pricePromo : this.cartList[i].price) * this.cartList[i].quantity
      }
      this.totalPrice = price
      return functionUtils.convertFormatNumberToMoney(price.toString())
    },
    /**
     * Get total price with fee
     */
    getTotalPriceWithFee: function () {
      let price = 0
      for (let i = 0, size = this.cartList.length; i < size; i++) {
        price += this.cartList[i].price * this.cartList[i].quantity
      }
      return functionUtils.convertFormatNumberToMoney(
        (price + (price * this.tax) / 100).toString()
      )
    },
    goToDeliveryInfo: function () {
      this.$router.push({
        path: '/mobile-portal-delivery-info'
      })
    },
    getVoucherOrderCart: async function () {
      let filter = {
        params: {
          storeChannelId: this.getStoreChannelId()
        }
      }
      await this.GET_VOUCHER_ORDER_CART_LIST(filter).then(
        function () {}.bind()
      )
    },
    updateVoucherOrderCart: function (id, quantity) {
      let data = {
        id: id,
        quantity: quantity
      }
      this.UPDATE_VOUCHER_ORDER_CART(data).then(
        function () {
          this.getVoucherOrderCart()
        }.bind(this)
      )
    },
    getPartnershipEntityId: function (entityId) {
      let partnershipEntityId = functionUtils.getSessionKey('partnershipEntityId')
      if (partnershipEntityId && this.getIsActiveLinkPartnership()) {
        return partnershipEntityId
      }
      return entityId
    },
    getIsActiveLinkPartnership: function () {
      const activate = isCookieEnabled() ? getCookie('isActiveLinkPartnership') : null
      if (activate === 'true') {
        return true
      } else {
        return false
      }
    },
    getStoreChannelId: function () {
      let partnershipEntityId = functionUtils.getSessionKey('partnershipEntityId')
      if (partnershipEntityId && this.getIsActiveLinkPartnership()) {
        return StoreChannelType.PRODUCT_PORTAL
      }
      return this.storeChannelsIdInRouteParams()
    },
    ...mapActions([
      'ON_SHOW_TOAST',
      'GET_DELIVERY_INFO_DETAIL',
      'GET_VOUCHER_ORDER_CART_LIST',
      'UPDATE_VOUCHER_ORDER_CART',
      'DELETE_VOUCHER_ORDER_CART',
      'GET_OBJECT_SETTING'
    ])
  }
}
</script>

<style lang="scss" scoped>
figure.sixteen-nine-img {
  width: 100%;
  overflow: hidden;
  margin: 0;
  padding-top: 56.25%;
  position: relative;
}
figure.sixteen-nine-img img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}
.disable-btn {
  color: #7d7d7d;
  background: #dfdfdf;
  cursor: no-drop;
}
</style>
